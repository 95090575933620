export const fiterWithSize = (unitData: any) => {
  const filterUnitData: any = unitData.sort((boxHeight: any, boxWidth: any) => {
    const heightValue: any =
      boxHeight.dimensions.length * boxHeight.dimensions.width;
    const widthValue: any =
      boxWidth.dimensions.length * boxWidth.dimensions.width;
    return parseFloat(heightValue) - parseFloat(widthValue);
  });

  return filterUnitData;
};

export const isPopularData = (filterData: any) => {
  return filterData.filter((unit: any) => unit.isPopular === true);
};

export const featuredData = (data: any) => {
  const featured = data.filter(
    (element: any) =>
      element.category === 'Small' ||
      element.category === 'Medium' ||
      element.category === 'Large' ||
      element.category === 'xLarge' ||
      element.category === 'parking'
  );

  return isPopularData(featured);
};

export const filterUnitsDataWithSize = (
  filterData: any,
  selectedcategory: any
) => {
  const res = filterData.filter((element: any) => {
    return element.category === selectedcategory;
  });
  return res;
};

export const removeUnitsDataWithSize = (
  filterData: any,
  selectedcategory: any
) => {
  const res = filterData.filter((element: any) => {
    return element.category !== selectedcategory;
  });

  return res;
};

export const filterUnitsDataWithFeature = (filterData: any, feature: any) => {
  const res = filterData.filter((element: any) => {
    if (feature === 'heated')
      return (
        element?.hasAmenities?.[feature] === true ||
        element?.hasAmenities?.climateControlled === true
      );
    return element?.hasAmenities?.[feature] === true;
  });
  return res;
};

export const filterUnitsDataWithBothfeatures = (
  filterData: any,
  heated = false
) => {
  if (heated) {
    const res = filterData.filter((element: any) => {
      return (
        element?.hasAmenities?.heated === true &&
        element?.hasAmenities?.driveUp === true
      );
    });

    return res;
  }
  const res = filterData.filter((element: any) => {
    return (
      element?.hasAmenities?.climateControlled === true &&
      element?.hasAmenities?.driveUp === true
    );
  });

  return res;
};

export const filterUnitsDataByfeatures: any = (
  baseData: any,
  heatedChecked: boolean,
  driveupChecked: boolean,
  climateControlledChecked: boolean
) => {
  let res: any = baseData;
  if (heatedChecked && driveupChecked) {
    res = filterUnitsDataWithBothfeatures(baseData, true);
  } else if (climateControlledChecked && driveupChecked) {
    res = filterUnitsDataWithBothfeatures(baseData, false);
  } else if (heatedChecked) {
    res = filterUnitsDataWithFeature(baseData, 'heated');
  } else if (driveupChecked) {
    res = filterUnitsDataWithFeature(baseData, 'driveUp');
  } else if (climateControlledChecked) {
    res = filterUnitsDataWithFeature(baseData, 'climateControlled');
  }
  return res;
};

export const isBothFeature = (data: any) => {
  return data?.some(
    (element: any) =>
      element?.hasAmenities?.heated === true &&
      element?.hasAmenities?.driveUp === true
  );
};

export const islanFrench = (str: any) => {
  // Needed this comments { location: [slug]}, {location: [city, slug]}
  const { location } = str;

  if (location && location.length && location.length > 1) {
    const slug = location && location[1];

    if (slug && slug.substring(slug.length - 3) === '-fr') {
      const finalSlug = slug.slice(0, -3);
      return { location: [location[1], finalSlug] };
    }
  } else {
    const slug = location && location[0];
    if (slug && slug.substring(slug.length - 3) === '-fr') {
      const finalSlug = slug.slice(0, -3);
      return { location: [finalSlug] };
    }
  }
  return str;
};

export const isIndoorParking = (filterData: any) => {
  // filter more than 10*15 and driveup except office category
  const filterUnitData: any = filterData.filter((ele: any) => {
    return ele?.hasAmenities?.indoorParking === true;
  });
  return filterUnitData;
};

export const facilityUrl = (item: any) => {
  const citySlug = item?.citySlug;
  if (item.city && item.slug) {
    return { location: [item.city, item.slug] };
  }
  if (item.multipleFacility && citySlug && item.slug) {
    return { location: [citySlug, item.slug] };
  }
  if (citySlug && !item.city) {
    return { location: [citySlug] };
  }
  return { location: [item.slug] };
};

export const facilityUrlHref = (locale: string, loc: any) => {
  let splicedStr = '';
  let url = '/';
  if (locale === 'fr') {
    let split;
    if (loc.location.length > 1) {
      split = loc.location[1].split('-');
      splicedStr = split.slice(0, split.length - 1).join('-');
      url = `/fr/entreposage-libre-service/${loc.location[0]}/${splicedStr}`;
    } else {
      url = `/fr/entreposage-libre-service/${loc.location[0]}`;
    }
  } else if (loc.location.length > 1) {
    [, splicedStr] = loc.location;
    url = `/en/self-storage/${loc.location[0]}/${splicedStr}`;
  } else {
    url = `/en/self-storage/${loc.location[0]}`;
  }
  return url;
};

export const isParking = (data: any) => {
  return data.some((element: any) => element.category === 'parking');
};

export const hasIndoorParkingUnits = (filterData: any) => {
  const existIndoorParking = filterData.some((ele: any) => {
    return ele.hasAmenities.indoorParking === true && ele.category !== 'office';
  });
  return existIndoorParking;
};

export const removeIndoorParkingUnitsData = (filterData: any) => {
  const res = filterData.filter((ele: any) => {
    return !(
      ele?.dimensions?.width >= 10 &&
      ele?.dimensions?.length >= 15 &&
      ele?.hasAmenities?.driveUp &&
      ele?.category !== 'office'
    );
  });
  return res;
};
