import { replaceWithRequiredSiteInfo } from '@/helper/wordReplace';
import { SITE_TITLE, STORE_NAME } from '@/utils/constants';

import SEO from '../../next-seo.config';

const getSection = (props: any) => {
  return props &&
    props.bodyData &&
    props.bodyData.data &&
    props.bodyData.data[0] &&
    props.bodyData?.data[0].attributes &&
    props.bodyData?.data[0].attributes.Section
    ? props.bodyData?.data[0].attributes.Section
    : props.bodyData &&
        props.bodyData.data &&
        props.bodyData.data[0] &&
        props.bodyData?.data[0].attributes.Storage_Units;
};
const getMovingSection = (props: any) => {
  return (
    props &&
    props.movingData &&
    props.movingData.attributes &&
    props.movingData.attributes.Section
  );
};
// eslint-disable-next-line consistent-return
const getCanonicalURL = (router: any) => {
  const cleanPath = router.asPath.split('#')[0].split('?')[0];
  return `${process.env.NEXT_PUBLIC_SITE_URL_ORIGIN}/${router.locale}${
    router.asPath === '/' ? '/' : cleanPath
  }`;
};

const prettify = (str: string) => {
  return str
    .split('-')
    .map(function capitalize(part) {
      return part.charAt(0).toUpperCase() + part.slice(1);
    })
    .join(' ');
};

const generateDynamicBCLD = (router: any) => {
  const cleanPath = router.asPath.split('#')[0].split('?')[0];
  const paths = cleanPath.split('/');
  const urls = [];
  paths.shift(); // Removes the first element from an array and returns only that element.
  paths.pop(); // Removes the last element from an array and returns only that element.

  urls.push({
    position: 1,
    name: router.locale === 'en' ? 'Home' : 'Accueil',
    item: `${process.env.NEXT_PUBLIC_SITE_URL_ORIGIN}/${router.locale}/`,
  });
  // eslint-disable-next-line no-plusplus
  for (let i = 0; i < paths.length; i++) {
    let urlPath = '';
    // eslint-disable-next-line  no-plusplus
    for (let j = 0; j < i + 1; j++) {
      urlPath += `${paths[j]}/`;
    }

    let pathName = prettify(paths[i]);
    if (pathName === 'Self Storage') {
      pathName = 'Locations';
    } else if (pathName === 'Entreposage Libre Service') {
      pathName = 'Succursales';
    } else if (pathName === 'Business') {
      pathName = 'Business Self Storage';
    } else if (pathName === 'Commercial') {
      pathName = 'Entreposage commercial en libre-service';
    } else if (pathName === 'Fournitures Emballage') {
      pathName = "Fournitures de déménagement et d'emballage";
    } else if (
      paths.includes('portable-storage') &&
      pathName === 'Residential'
    ) {
      pathName = 'Residential Portable Storage';
    } else if (
      pathName === 'Residentiel' &&
      paths.includes('entreposage-mobile')
    ) {
      pathName = 'Entreposage mobile résidentiel';
    } else if (
      paths.includes('entreposage-mobile') &&
      pathName === 'Entreposage Commercial'
    ) {
      pathName = 'Conteneurs mobiles pour entreposage commercial';
    } else if (
      paths.includes('portable-storage') &&
      pathName === 'Business Storage'
    ) {
      pathName = 'Portable Storage Containers For Business';
    } else if (paths.includes('portable-storage') && pathName === 'Moving') {
      pathName = 'Portable Storage For Moving';
    } else if (
      paths.includes('entreposage-mobile') &&
      pathName === 'Demenagement'
    ) {
      pathName = 'Entreposage mobile pour déménager';
    } else if (
      paths.includes('entreposage-mobile') &&
      pathName === 'Demenagement Longue Distance'
    ) {
      pathName = 'Déménagement sur longue distance';
    } else if (paths.includes('self-storage') && pathName === 'Moving') {
      pathName = 'Moving Storage';
    } else if (
      paths.includes('entreposage-libre-service') &&
      pathName === 'Demenagement'
    ) {
      pathName = 'Entreposage mobile';
    }
    urls.push({
      position: i + 2,
      name: pathName,
      item: `${process.env.NEXT_PUBLIC_SITE_URL_ORIGIN}/${router.locale}/${urlPath}`,
    });
  }
  return urls;
};

const getVimeoIdFromUrl = (url: string) => {
  const match = /vimeo.*\/(\d+)/i.exec(url);
  if (match) {
    return match[1];
  }
  return null;
};

const getSEOProps = (props: any, router: any) => {
  return {
    ...SEO,
    title: props?.Title,
    description: props?.Description,
    keyworkds: props?.Keywords,
    openGraph: {
      title: props?.Open_Graph?.Title,
      description: props?.Open_Graph?.Description,
      url: getCanonicalURL(router),
      images: [
        {
          url: props?.Open_Graph?.Image?.data?.attributes?.url,
          width: props?.Open_Graph?.Image?.data?.attributes?.width,
          height: props?.Open_Graph?.Image?.data?.attributes?.height,
          alt: props?.Open_Graph?.Image?.data?.attributes?.alternativeText,
        },
      ],
    },
  };
};
function getStoreHours(storeHours: any) {
  const structuredstoreHours: any[] = [];
  if (storeHours?.Monday) {
    structuredstoreHours.push(
      storeHours?.Monday && {
        opens: storeHours?.Monday?.split('-')[0],
        closes: storeHours?.Monday?.split('-')[1],
        dayOfWeek: 'Monday',
      }
    );
  }
  if (storeHours?.Tuesday) {
    structuredstoreHours.push(
      storeHours?.Tuesday && {
        opens: storeHours?.Tuesday?.split('-')[0],
        closes: storeHours?.Tuesday?.split('-')[1],
        dayOfWeek: 'Tuesday',
      }
    );
  }
  if (storeHours?.Wednesday) {
    structuredstoreHours.push(
      storeHours?.Wednesday && {
        opens: storeHours?.Wednesday?.split('-')[0],
        closes: storeHours?.Wednesday?.split('-')[1],
        dayOfWeek: 'Wednesday',
      }
    );
  }
  if (storeHours?.Thursday) {
    structuredstoreHours.push(
      storeHours?.Thursday && {
        opens: storeHours?.Thursday?.split('-')[0],
        closes: storeHours?.Thursday?.split('-')[1],
        dayOfWeek: 'Thursday',
      }
    );
  }
  if (storeHours?.Friday) {
    structuredstoreHours.push(
      storeHours?.Friday && {
        opens: storeHours?.Friday?.split('-')[0],
        closes: storeHours?.Friday?.split('-')[1],
        dayOfWeek: 'Friday',
      }
    );
  }
  if (storeHours?.Saturday) {
    structuredstoreHours.push(
      storeHours?.Saturday && {
        opens: storeHours?.Saturday?.split('-')[0],
        closes: storeHours?.Saturday?.split('-')[1],
        dayOfWeek: 'Saturday',
      }
    );
  }
  if (storeHours?.Sunday) {
    structuredstoreHours.push(
      storeHours?.Sunday && {
        opens: storeHours?.Sunday?.split('-')[0],
        closes: storeHours?.Sunday?.split('-')[1],
        dayOfWeek: 'Sunday',
      }
    );
  }
  return structuredstoreHours;
}

const generateLocalBusinessStructerData = (
  facilityData: any,
  unitsresultsData: any,
  reviewsData: any,
  router: any
) => {
  return {
    type: 'LocalBusiness',
    id: process.env.NEXT_PUBLIC_SITE_URL_ORIGIN,
    name: `${STORE_NAME} - ${facilityData?.Title}`,
    description: facilityData?.SEO?.Description,
    url: getCanonicalURL(router),
    telephone: facilityData?.Support?.Phone_Number,
    address: {
      '@type': 'PostalAddress',
      streetAddress: facilityData?.Address?.Street_2
        ? `${facilityData?.Address?.Street_1} ${facilityData?.Address?.Street_2}`
        : facilityData?.Address?.Street_1,
      addressLocality: facilityData?.Address?.City,
      addressRegion: facilityData?.Province?.data?.attributes?.Name,
      postalCode: facilityData?.Address?.Postal_Code,
      addressCountry: 'Canada',
    },
    geo: {
      latitude: facilityData?.Address?.Latitude,
      longitude: facilityData?.Address?.Longitude,
    },
    rating: {
      ratingValue: reviewsData?.averageRating,
      ratingCount: reviewsData?.numberOfReviews,
    },
    review: reviewsData?.highlightedReviews.map((review: any) => {
      const reviewList: any = {
        author: review?.name,
        datePublished: review?.date,
        reviewBody: review?.text,
        reviewRating: {
          bestRating: '5',
          worstRating: '1',
          ratingValue: review?.rating,
        },
      };
      return reviewList;
    }),
    priceRange: `$${Math.min(
      // eslint-disable-next-line no-unsafe-optional-chaining
      ...unitsresultsData?.map((units: any) => units.rate)
    )}-$${Math.max(
      // eslint-disable-next-line no-unsafe-optional-chaining
      ...unitsresultsData?.map((units: any) => units.rate)
    )}`,
    openingHours: getStoreHours(facilityData?.Store_Hours),
    images: [facilityData?.SEO?.Open_Graph?.Image?.data?.attributes?.url],
  };
};
const generateDynamicFAQ = (
  faqs: [] = [],
  isFaqInfo: boolean = false,
  brand: string = SITE_TITLE,
  brandUrl: any = ''
) => {
  const faqStructure: any = [];
  faqs.forEach((faq: any) => {
    const faqTitle = isFaqInfo ? faq?.Title : faq?.attributes?.Title;
    const faqContent = isFaqInfo ? faq?.Description : faq?.attributes?.Content;
    const noPTag = faqContent?.replace(/<[^>]+>/g, '');

    const getSiteUrlName = replaceWithRequiredSiteInfo(
      brand,
      brandUrl,
      faqTitle
    );
    const getSiteUrlNameDesc = replaceWithRequiredSiteInfo(
      brand,
      brandUrl,
      noPTag
    );

    faqStructure.push({
      questionName: getSiteUrlName,
      acceptedAnswerText: getSiteUrlNameDesc,
    });
  });

  return faqStructure;
};

const allowNumbers = (e: any) => {
  const pattern = /^[0-9\b]+$/;
  return pattern.test(e);
};

const getOfferSlugValue = (locale: any) => {
  const coords = JSON.parse(sessionStorage.getItem('offCords'));

  return (
    coords &&
    coords.find((e: any) => {
      return e.lan === locale;
    })?.slugValue
  );
};

const getBlogSlugValue = (locale: any) => {
  const coords = JSON.parse(sessionStorage.getItem('blogCords'));

  return (
    coords &&
    coords.find((e: any) => {
      return e.lan === locale;
    })?.slugValue
  );
};

export {
  allowNumbers,
  generateDynamicBCLD,
  generateDynamicFAQ,
  generateLocalBusinessStructerData,
  getBlogSlugValue,
  getCanonicalURL,
  getMovingSection,
  getOfferSlugValue,
  getSection,
  getSEOProps,
  getVimeoIdFromUrl,
};
