/* eslint-disable @next/next/no-before-interactive-script-outside-document */
import 'styles/scss/styles.scss';

import type { AppProps } from 'next/app';
import dynamic from 'next/dynamic';
import Head from 'next/head';
import { useRouter } from 'next/router';
import Script from 'next/script';
import { DefaultSeo } from 'next-seo';
import { useEffect, useMemo } from 'react';
import { IntlProvider } from 'react-intl';

import { FilterDataContextWrapper } from '@/context/FilterDataContext';
import { HeaderFooterContextWrapper } from '@/context/HeaderFooterContext';
import { LocationContextWrapper } from '@/context/LocationContext';
import { MapCordinateContextWrapper } from '@/context/MapCordinateContext';
import { StoreDataContextWrapper } from '@/context/StoreDataContext';
import { en, fr } from '@/lang';
import { handleInvendr } from '@/utils/invendr';

import SEO from '../../next-seo.config';

const ErrorBoundary = dynamic(() => import('@/components/common/error'));
const Layout = dynamic(() => import('@/components/laylout'));

const langList: any = {
  fr,
  en,
};
function getDirection(locale: any) {
  if (locale === 'ar') {
    return 'rtl';
  }

  return 'ltr';
}

declare global {
  interface InvendrData {
    term: any;
    content: any;
    medium: any;
    campaign: any;
    source: any;
    mediaNumber: any;
    active_rule: any;
  }

  interface Window {
    dni_invendr_callback: (data: any) => void;
    invendr_data_cookie: InvendrData;
    invendr_data_next: InvendrData;
    invendr_data_dni_raw: any;
    invendr_data_dni: InvendrData;
    invendrData: InvendrData; // main variable, used for form payloads
    invendr_data_sources: {
      invendr_data_cookie: InvendrData;
      invendr_data_next: InvendrData;
      invendr_data_dni_raw: InvendrData;
      invendr_data_dni: InvendrData;
      invendrData: InvendrData;
    };
    invendrLog: string[];
    intercomLog: string[];
    Intercom: any;
    phone: string;
    email: string;
  }
}

function MyApp({ Component, pageProps }: AppProps) {
  useEffect(() => {
    handleInvendr();
    import('bootstrap');
  }, []);

  const { locale } = useRouter();

  const [lang] = locale ? locale.split('-') : ['en'];

  const messages: any = useMemo(() => {
    return langList[lang];
  }, [lang]);

  return (
    <>
      {/* <GooglePlacesScript /> */}
      <Head>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, shrink-to-fit=no, maximum-scale=1"
        />
        <meta charSet="UTF-8" />
        <meta httpEquiv="Content-Type" content="text/html" charSet="utf-8" />
      </Head>
      <Script id="gtm" strategy="beforeInteractive">
        {`(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','GTM-PW6TJW3')`}
      </Script>
      <DefaultSeo {...SEO} />
      <HeaderFooterContextWrapper>
        <MapCordinateContextWrapper>
          <LocationContextWrapper>
            <StoreDataContextWrapper>
              <FilterDataContextWrapper>
                <IntlProvider locale={lang} messages={messages}>
                  <ErrorBoundary>
                    <Layout>
                      <Component {...pageProps} dir={getDirection(lang)} />
                    </Layout>
                  </ErrorBoundary>
                </IntlProvider>
              </FilterDataContextWrapper>
            </StoreDataContextWrapper>
          </LocationContextWrapper>
        </MapCordinateContextWrapper>
      </HeaderFooterContextWrapper>

      {/* Global Site Tag (gtag.js) - Google Analytics
      <Script
        strategy="afterInteractive"
        src={`https://www.googletagmanager.com/gtag/js?id=${gtag.GA_TRACKING_ID}`}
      />
      <Script
        id="gtag-init"
        strategy="afterInteractive"
        dangerouslySetInnerHTML={{
          __html: `
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', '${gtag.GA_TRACKING_ID}', {
              page_path: window.location.pathname,
            });
          `,
        }}
      /> */}
    </>
  );
}

export default MyApp;
